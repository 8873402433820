<template>
  <div class="body_">
    <my-fram>
      <div slot="default" class="content">
        <div class="top">
          <div class="top_left">当前位置: 房屋信息</div>
          <div class="top_right">
            <div class="button" @click="goQuery">返回</div>
          </div>
        </div>
        <div class="foot">
          <div class="head">
            <el-row class="elrow" justify="center">
              <el-col :span="4" class="elcol">姓名</el-col>
              <el-col :span="4" class="elcol">小区名称</el-col>
              <el-col :span="3" class="elcol">楼栋号</el-col>
              <el-col :span="3" class="elcol">单元</el-col>
              <el-col :span="3" class="elcol">户室</el-col>
              <el-col :span="3" class="elcol">面积</el-col>

              <el-col :span="4" class="elcol">账户余额</el-col>
            <!--  <el-col :span="2" class="elcol">操作</el-col>-->
            </el-row>
          </div>
          <div class="one">
            <el-row class="ct" v-for="(item,index) in tableData" >
              <el-col :span="4" class="elcol">{{ item.ownerName}}</el-col>
              <el-col :span="4" class="elcol">{{item.villageName}}</el-col>
              <el-col :span="3" class="elcol">{{ item.towerNum}}</el-col>
              <el-col :span="3" class="elcol">{{ item.unitName}}</el-col>
              <el-col :span="3" class="elcol">{{ item.houseNum}}</el-col>
              <el-col :span="3" class="elcol">{{ item.coveredarea}}</el-col>
              <el-col :span="4" class="elcol">{{ item.houseacctBal}}</el-col>
             <!-- <el-col :span="2" class="elcol">
                <el-button type="text" @click="dialogVisible = true">
                  <span class="xq">详情</span>
                </el-button>
              </el-col>-->
              <el-dialog
                class="fakeDialog"
                title="房屋详情"
                :visible.sync="dialogVisible"
                width="50%"
                :before-close="close"
                :show-close="false"
                :close-on-click-modal="false">
                <div class="dialogBox">
                  <p><span>小区名称</span><span>富润家园</span></p>
                  <p><span>楼栋号</span><span>1号楼</span></p>
                  <p><span>单元</span><span>2</span></p>
                  <p><span>户室</span><span>1001</span></p>
                  <p><span>面积</span><span>134.78</span></p>
                  <p><span>业主姓名</span><span>吕布</span></p>
                  <p><span>身份证号</span><span>622826199012121212</span></p>
                  <p><span>手机号</span><span>13809310931</span></p>
                  <p><span>非税凭证号</span><span>87221123</span></p>
                  <p><span>标准金额</span><span>7805.00</span></p>
                  <p><span>账户余额</span><span>7800.00</span></p>
                  <p><span>缴存银行</span><span>中国农业银行</span></p>
                  <p><span>缴存渠道</span><span>掌上银行</span></p>
                  <p><span>缴费时间</span><span>2022-06-02 10:33:07</span></p>
                </div>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisible = false" class="dialog_button">返回</el-button>
                </span>
              </el-dialog>
            </el-row>
          </div>
        </div>
      </div>
    </my-fram>
  </div>
</template>

<script>
import MyFram from '@/components/myFram'

export default {
  name: 'Housing',
  components: {MyFram},
  data() {
    return {
      dialogVisible: false,
      url: this.$Config.base_server,
      tableData:[]
    }
  },

  mounted() {
    var idCard= this.$utils.getIdcard();
    if (idCard==""){
      this.$alert("请将身份证放识别区","消息提示");
      return false;
    }

    this.$axios.post(this.url+'/getHouse?idCard='+idCard).then((res)=>{
      if (res.data.resp_code=='0'){
        this.tableData=res.data.datas;
      }else{
        this.$message.error('对不起,查询出错了');
      }
    })
  },
  methods: {
    goQuery() {
      this.$router.push({path: '/'})
    }
  }
}
</script>

<style scoped lang="stylus">
.body_{
  height: 100%;
  width: 100%;
}
.xq{
  background-image : linear-gradient(180deg, #E0E7FF 0%, #BFCFFF 100%);
  border :0.0625rem solid #FFFFFF;
  border-radius :0.25rem;
  margin-right 0.625rem
  padding 0.25rem 0.625rem
  color :#162E85;
}
.content{
  height :100%
  width :100%
  display :flex;
  flex-direction :column;
  .top{
    width 100%
    height 15%
    display flex
    flex-direction row
    .top_left{
      width 65%
      line-height 100%
      font-size 1.725rem
      display flex
      flex-direction column
      justify-content center
      justify-items center
      color: #FFFFFF;
    }
    .top_right{
      width 35%
      line-height 100%
      font-size 1.725rem
      display flex
      flex-direction row
      justify-content right
      align-items center
      .button{
        width 5rem
        height 2.5rem
        line-height 2.4rem
        text-align center
        background-image linear-gradient(to bottom, #BDE4DD,#06B198)
        border-radius :0.3rem
        font-size 1.2rem
        color #fff
        margin-right 1rem
        cursor pointer
      }
    }
  }
  .foot{
    width 100%
    height 85%
    .head{
      border-top-left-radius :1rem;
      border-top-right-radius :1rem;
      border-left :1px solid #82D5FF80;
      border-top :1px solid #82D5FF80;
      border-right :1px solid #82D5FF80;
      height 12%;
      background #019B84
      font-weight :600;
      font-size 0.9rem;
      color :#FFFFFF;
      .elrow{
        height 100%
        display flex
        flex-direction row
        align-items center
        justify-content center
        .elcol{
          text-align center
          font-size 1.4rem !important
        }
      }
    }

    .one{
      width 100%
      height 88%
      background #DBEEEC
      display flex
      flex-direction column
      border-bottom-left-radius :1rem
      border-bottom-right-radius :1rem
      border-left: 1px solid #82D5FF80 ;
      border-bottom: 1px solid #82D5FF80 ;
      border-right: 1px solid #82D5FF80 ;
      .ct{
        height calc(90% / 7)
        font-size :0.8rem
        color #333333
        display flex
        flex-direction row
        align-items center
        border-bottom 1px solid #6d979f
        .elcol{
          text-align center
          font-size 1.4rem
        }
        .xqlb{
          width: 120px;
          height: 500px;
          opacity: 0.6;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 24px;
          color: #000000;
          letter-spacing: 0;
        }
      }
    }
  }
}
// 对话框样式
 /deep/ .fakeDialog {
  .el-dialog__header {
    padding-left 0
    border-bottom: 1px solid #e3e8ee;
    background-color #F1F4FF;
    border-top-right-radius 10px
    border-top-left-radius 10px
  }

  .el-dialog__body {
    background-color #F1F4FF;
  }

  .el-dialog__footer {
    background-color #F1F4FF;
    border-bottom-left-radius 10px
    border-bottom-right-radius 10px
    text-align center
  }

}

.dialogBox {
  width 100%
  border-bottom: 1px solid #e3e8ee

  p {
    width 60%
    display flex
    align-items center
    justify-items center
    margin 0 auto
    line-height 33px

    span {
      display inline-block
      width: 35%;
      height: 100%;
      opacity: 0.6;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 16px;
      color: #000000;
      letter-spacing: 0;
      text-align: right;
    }

    span:nth-of-type(2) {
      margin-left 30px
      display inline-block
      width: 80%;
      height: 100%;
      opacity: 0.6;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 16px;
      color: #000000;
      letter-spacing: 0;
      text-align: left;
    }
  }
}
.dialog_button{
  background-image: linear-gradient(180deg, #FFFFFF 0%, #E9EDFF 100%);
  border: 1px solid #8E9CC1;
  border-radius: 6px;
}
</style>
