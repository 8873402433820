<template>
  <div class="m">
    <div class="first">
      <div class="first_left">
        <div class="tnamec">住宅维修资金云管理平台自助服务</div>
        <div class="tnamee">Residential self-service fund management platform</div>
      </div>
      <div class="first_right">
        <div class="fr1">{{Samplingtime}}</div>
        <div class="fr2"></div>
      </div>
    </div>
    <div class="second">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myFram',
  data () {
    return {
      Samplingtime: ''
    }
  },
  created () {
    this.$axios.post('http://weather.cma.cn/api/weather/view?stationid=').then(res => {
      this.weather = res.data.data.daily[0]
    })
  },
  mounted () {
    this.getCurrentTime()
    // eslint-disable-next-line no-use-before-define
    clearInterval(myTimeDisplay)
    const myTimeDisplay = setInterval(() => {
      this.getCurrentTime()
    }, 1000)
  },
  methods: {
    getCurrentTime () {
      var _this = this
      const yy = new Date().getFullYear()
      const mm = (new Date().getMonth() + 1) < 10 ? ('0' + (new Date().getMonth() + 1)) : (new Date().getMonth() + 1)
      const dd = new Date().getDate() < 10 ? ('0' + new Date().getDate()) : new Date().getDate()
      const hh = new Date().getHours() < 10 ? ('0' + new Date().getHours()) : new Date().getHours()
      const mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
      _this.gettime = yy + '年' + mm + '月' + dd + '日 ' + hh + ':' + mf + ':' + ss
      this.Samplingtime = _this.gettime
    }
  }
}
</script>

<style scoped lang="stylus">
.m{
  background #BCE1DF
  height: 100%;
  width: 100%;
  padding: 0.625rem 0.625rem ;
  .first{
    width: 100%;
    height: 10%;
    background: rgba(9, 25, 103, 0.4);
    background #006666 url("../assets/image/title_bg.png") no-repeat center
    background-size 100% 100%
    display flex;
    flex-direction row
    .first_left{
      width 50%;
      height 100%
      .tnamec{
        margin-top 0.75rem
        margin-left 1.25rem
        font-size: 1.625rem;
        color: #FFFFFF;
        letter-spacing: 0;
        text-shadow: 0 0.25rem 0.625rem rgba(0,20,119,0.20);
      }
      .tnamee{
        margin-left 1.25rem
        font-size: 0.875rem;
        color: #FFFFFF;
        letter-spacing: 0.9px;
        text-shadow: 0 0.25rem 0.625rem rgba(0,20,119,0.20);
      }
    }
    .first_right{
      width 100%
      display flex
      flex-direction column
      .fr1{
        height 70%
        display flex
        flex-direction row
        align-items center
        justify-content right
        padding-right 1.25rem
        padding-top 0.375rem
        font-weight: 400;
        font-size: 1.25rem;
        color: #FFFFFF;
        letter-spacing: 0;
      }
      .fr2{
        height 30%
        border-right 0.125rem solid #3F52AF;
      }
    }
  }
  .second{
    background #006666
    width: 100%;
    height: 90%;
    border-bottom:  0.125rem solid #3F52AF;
    border-left:  0.125rem solid #3F52AF;
    border-right:  0.125rem solid #3F52AF;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    padding: 0.625rem 1.875rem;
    display: flex;
    flex-direction: row;
  }
}

</style>
